/*custom styles*/
html,body{height: 100%;width:100%;}
.height_100{height: 100%;}
.bg_overlay {background: #101010d4;; position: absolute;height: 100%;width: 100%;}
.flex_box{display: flex;align-items: center;justify-content: center;}
.flex_btwn{display: flex;align-items: center;justify-content: space-between;}
.flex_end{display: flex;align-items: center;justify-content: flex-end;}
.flex_center{display: flex;align-items: center;}
.form-control:focus {box-shadow: 0 0 0 0px rgba(0,123,255,.25);border-color: #80bdff !important;}
.pad_0{padding:0;}
.pad_15{padding: 15px;}
.margin_0{margin: 0;}
.form-control:disabled, .form-control[readonly] {background-color: #ffffff;}
.form-control {padding-left: 0; border: 0px solid #ced4da;}
.edit_patient_view .form-control {border: 0px solid #ced4da;border-bottom: 1px solid #e6e6e6;border-radius: 0;}
.btn-primary {background: #70bfe4;border: none;min-width: 90px;min-height: 35px;color: white;border-radius: 100px;padding: 0 20px;}
.btn-primary:hover {background-color: #70bfe4;}
.pointer{cursor: pointer;}
.flex_column{flex-direction: column;}
.w_13{width: 13px;}
.table {box-shadow: 0 2px 1px -1px rgba(0,0,0,.2), 0 1px 1px 0 rgba(0,0,0,.14), 0 1px 3px 0 rgba(0,0,0,.12);}
.box-shadow{box-shadow: 0 2px 1px -1px rgba(0,0,0,.2), 0 1px 1px 0 rgba(0,0,0,.14), 0 1px 3px 0 rgba(0,0,0,.12);}
/*color-codes*/
.new_patient{color: red;}
.visited_patient{color: #00bd00;}
.appoinment_sheduled{color: #f5be1b;}
.patient_contacted{color: blue;}
.primary {color: #70bfe4;}
/*css-scrollbar*/
::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}
::-webkit-scrollbar-button {
  width: 0px;
  height: 0px;
}
::-webkit-scrollbar-thumb {
  background: #666666; /*e1e1e1*/
  border: 0px none #ffffff;
  border-radius: 50px;
}

::-webkit-scrollbar-track {
  background: #e1e1e1;
  border: 0px none #ffffff;
  border-radius: 50px;
}
::-webkit-scrollbar-track:hover {
  background: #ccc;
}
::-webkit-scrollbar-corner {
  background: transparent;
}
/*scrollbar-end*/
/*Login Page*/
.login,.forgot-pass,.change-pass {height: 100vh; background-image: url(../images/login_bg.jpg);background-position: center;background-repeat: no-repeat;background-size: cover;}
.login .login_section,.forgot-pass .login_section,.change-pass .login_section{background: white;overflow: hidden;border-radius: 4px; box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);}
.login .login_container, .forgot-pass .login_container,.change-pass .login_container{height: 500px;width: 100%;}
.login_left {background: #6fc1e8;padding: 40px 15px;display: flex;align-items: center;justify-content: space-between;flex-direction: column;}
.login_right {background: #fafbfc;padding: 40px;}
.login_footer {height: 40px;display: flex;align-items: flex-end;justify-content: center;width: 100%;border-top: 1px solid #ffffff75;color: white;font-size: 14px;}
.login .form-control {border: 1px solid #ced4da59;border-radius: 100px;padding-left: 10px;}
.login form{width: 325px;}
.Login-form .form-group {min-height: 65px;margin-bottom: 0;}
.forgot_password {font-size: 12px;text-align: right;width: 100%;color: #6fc1e8;}
.space_between_fields { margin: 10px; }
.login_btn{background: #6fc1e8;color: white;border-radius: 100px;font-size: 15px;padding: 5px 15px;margin: 0 2px;min-width: 100px;}
.login_btn:hover{color:#fff;background: #6fc1e8;}
.Login-form .form-control{font-size:14px;}
.login_title{color:#6fc1e8; width:325px;}
/*login ends*/
/*dashboard*/
/*Reffered Patients*/
.dashboard_header .right {border-bottom: 1px solid #eeeeee;}
.dashboard .left{background: #313942;}
.dashboard_height{height: calc(100vh - 100px);}
.dashboard_height .right{background: #eeeeee;overflow-y: auto;}
.dashboard_header {height: 70px;}
.dashboard_header .left{background: #70bfe4;}
.menu_title {padding: 0 15px;border-bottom: 1px solid #21262c;color: white;height: 50px;display: flex;align-items: center;text-transform: uppercase;font-size: 14px;letter-spacing: 1px;width:100%;}
.menu_title a:hover{color:#fff;}
.border_btm ul {padding: 0;color: #616972;font-size: 14px;margin: 20px 0;font-weight: 500;letter-spacing: 0.2px;}
.border_btm ul li {list-style: none;display: flex;align-items: center;}
.border_btm ul li svg{margin-right:8px;}
.border_btm ul li svg path{fill:#6B737C;}
.border_btm ul li a{color:#616972;text-transform: uppercase;text-decoration: none;width:100%;padding: 10px 5px;}
.border_btm ul li a:hover,.border_btm ul li a.selected{background: #637484;cursor: pointer;color: white;border-left: 5px solid #70bfe4;padding: 10px 5px;}
.border_btm ul li:hover a{color:#fff;}
.border_btm ul li a.selected svg path{fill:#fff;}
.dashboard_header .left img {width: 180px;}
.notify-sec .dropdown-toggle::after {display: none;}
.page_heading {font-size: 20px;color: #313942;}
.profile img {width: 30px;}
.dropdown-menu-right .dropdown-item{display:flex;align-items: center;justify-content: space-between;border-bottom: 1px solid rgba(204, 204, 204, 0.2);}
.dropdown-menu-right .dropdown-item:last-child{border:none;}
.dropdown-menu-right .dropdown-item img{width:15px;}
.notify-sec .dropdown-menu{min-width:270px;}
.notify-sec .dropdown-item{display:flex;align-items: flex-start;flex-direction: column;}
.notify-sec .dropdown-item h4{font-size: 14px;font-weight: normal;}
.notify-sec .dropdown-item h5{font-size: 12px;font-weight: bold;}
.view-lnk{color:#6fc1e8;align-items: flex-end !important;cursor: pointer;font-size:12px;}
.btn-primary:not(:disabled):not(.disabled).active:focus, .btn-primary:not(:disabled):not(.disabled):active:focus, .show>.btn-primary.dropdown-toggle:focus {box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0);}
.drop_dn{background: transparent;color: #7f8196;border: transparent;}
.btn.focus, .btn:focus {outline: 0;box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0);}
.notify{margin: 0px 10px;}
.notify .badge{background: red;color: white;border-radius: 10px;padding: .35em .6em;min-width:25px;}
.notify sup{left: -1em;top:-0.1em;}
.table td, .table th {border-top: 0px solid #dee2e6;}
.table-striped tbody tr:nth-of-type(odd) {background-color: #F3F5F7;}
.table-striped tbody tr:nth-of-type(even){background-color: white;}
.table thead th {vertical-align: bottom;border-bottom: 0px solid #dee2e6;color: #313942;font-size: 13px;}
tbody,thead {background: white;}
.table-striped tbody tr {color: #85919F;font-size: 14px;}
.total_count {font-size: 12px;padding: 0 10px;color: #85919F;}
.page-link:hover {color: #ffffff;text-decoration: none;background-color: #70bfe4;border-color: #dee2e6;}
.page-link {color: #85919F;height: 35px;width: 40px;display: flex;align-items: center;justify-content: center;}
.page-link:focus {box-shadow: 0 0 0 0rem rgba(0,123,255,.25);}
.search_panal{min-height: 30px; background: white; margin-bottom: 15px; padding: 20px 0;box-shadow: 0 2px 1px -1px rgba(0,0,0,.2), 0 1px 1px 0 rgba(0,0,0,.14), 0 1px 3px 0 rgba(0,0,0,.12);}
.search_bx input {border: none;border-bottom: 1px solid #ccc;border-radius: 0;padding-left: 0;}
.mr-3.mb-0 ~ select {border-radius: 100px;    color: #85919F !important;padding: 0px 10px;}
/*Reffered Patients ends*/
.panal_header {padding: 0 15px;color: #70bfe4;font-size: 16px;padding-bottom: 15px;margin-bottom: 15px;border-bottom: 1px solid #cccccc47;}
.panal_header img {width: 24px;margin-left: 5px;}
.search_panal label {font-weight: 700;color: #818488;margin-bottom: .2rem;}
.search_panal .form-control {font-size: 16px; color: #51575f;}
.item_image img{width: 100%; border-radius: 3px;}
.item_image {width: 150px; margin-right: 5px;position: relative;}
.multi_item_image {margin-right: 5px;position: relative;}
.select_image {position: absolute;margin-left:25px;top: 5px;width: 20px;height: 30px;display: flex;alignItems: center;justifyContent: center;borderRadius: 13px}
.img_thumb_widh {min-width: 100px}
.image_panel {display: flex;flex-wrap: wrap;}
/*modal_popup*/
.modal-title {color: #70bfe4;font-size: 17px;}
.modal-footer .btn-primary {border-radius: 5px;background: #70c5eb;padding: 0 20px;font-weight: 500;min-height:45px;}
.custom_footer_body{border: 1px dashed #cccccc80;margin: 15px;min-height: 220px;}
.custom_footer_body ~ .modal-footer {border: 0;}
.modal-header {border-bottom: 1px solid #e9ecef38;}
.browse{color: #ccc; padding: 10px 0;}
/*dashboard ends*/
.labelcount{position: absolute;min-width: 35px;padding: 1px 3px;background: #D72A3F;color: #fff;font-size: 12px;border-radius: 20px;left: 45px;top: 8px;}
.border_btm ul li:hover svg path{fill: #fff;}
.forgot-pass h2,.change-pass h2{text-align: center;margin: 5px 0px 30px 0px;color: #6fc1e8;}
.forgot-pass p{margin-bottom: 30px;color:#928f8f;}
.forgot-pass input{height: 55px;text-align: center;font-size: 16px;}
.reset-btn{min-height: 45px;min-width: 250px;background: #6fc1e8;color: #fff;margin-top: 10px;}
.reset-btn:hover{background: #42b3e8;color:#fff;}
.forgot-pass input::-webkit-input-placeholder,.forgot-pass input::placeholder,.forget-placeholder input::-webkit-input-placeholder,.forget-placeholder input::placeholder{
  color: #b7b7b7 ;
}
/*dashboard*/
.box {background: white;padding: 15px 10px;color: #abb2bb;border-radius: 3px;cursor: pointer;box-shadow: 0 2px 1px -1px rgba(0,0,0,.2), 0 1px 1px 0 rgba(0,0,0,.14), 0 1px 3px 0 rgba(0,0,0,.12);}
.box span:first-child {font-size: 26px;padding: 10px 0;border-bottom: 1px solid #eee;width: 100%;text-align: center;}
.box span:nth-child(2) {font-size: 13px;padding: 15px 0 10px 0;width: 100%;text-align: center;letter-spacing: 0.5px;font-weight: 500;}
/*end dashboard*/
.user_sec {
  border-right: 1px solid #eee;
}
.profile_pic {
  padding: 15px 0;
}
.user_name {
  color: #70bfe4;
  font-size: 18px;
  font-weight: 500;
}
.profile_edit {
  background: #70bfe4;
  width: 40px;
  height: 40px;
  border-radius: 100px;
  position: absolute;
  top: 30px;
  right: 245px;
}
.profile_edit img {
  width: 18px;
}
.change_password {
  font-size: 14px;
  width: max-content;
  color: #6fc1e8;
  padding: 10px 0px 3px 0;
  border-bottom: 1px solid #6fc1e878;
}
.search_panal select {
  border: 1px solid #ccc;
}
/*customserach box*/
.search_bx input{min-width:230px;}
.search_bx button{border-bottom: 1px solid #ccc;}
.custom-filter{min-width:60%;}
.custom-filter option{border-bottom: 1px solid #ccc;}
.back-btn{width:10px !important;}
.title-txt{font-size: 18px;}
.img-close{position: absolute;width: 30px;background: #fff;display: flex;align-items: center;justify-content: center;padding: 5px;height: 30px;top: -5px;right: -2px;border-radius: 50%;border: 0.1px solid #dedede;}
.img-close img{width: 10px;}
.ReactModal__Content{width:70%; padding: 15px !important;}
.model-header{display:flex;align-items: center;justify-content: space-between;border-bottom: 1px solid #eaeaea;padding: 0px 0px 10px;}
.model-header span{position: absolute;right: 18px;top: 15px;}
.model-header img{width:12px;}
.model-header h2{color:#818080;font-size:1.5rem;}
.forgot-pass .form-control{border-bottom: 2px solid #e6e6e6;background: transparent;}
.change-pass .form-control{border-bottom: 2px solid #e6e6e6;background: transparent;min-height:40px;}
.change-pass label{font-weight: 700;color: #212529;}
.img-subupload{opacity:0;transition: opacity .5s ease-out;
  -moz-transition: opacity .5s ease-out;
  -webkit-transition: opacity .5s ease-out;
  -o-transition: opacity .5s ease-out;
  position: absolute;margin: auto;top: 0;bottom: 0;left: 0;right: 0;width: 50px;background: rgba(255, 255, 255, 0.6);display: flex;align-items: center;justify-content: center;padding: 5px;height: 50px;border-radius: 50%;border: 0.1px solid #dedede;margin:auto;}
.img-subupload img{width:25px;}
.image_panel .item_image:hover .img-subupload{opacity:1;}
.view-form .form-control{border-bottom: 0 !important;}
.border-b{border-bottom: 1px solid #eee;}
.login-error{padding-left:15px;font-size: 14px;}
.login-msg{min-height: 40px;width: 100%;text-align: center;font-size: 14px;}
.success-msg{min-height: 40px;width: 100%;text-align: center;font-size: 14px;color: green;}
.error-msg{min-height: 40px;width: 100%;text-align: center;font-size: 14px;color: red;}
.field-error{padding-left:15px;font-size: 14px;color:red}
.btn-width{min-width: 100px;}
.msg-error{color:red;}
.msg-success{color:green;}
.status-notes-description{color: #055664;}
.btn-default{background: #b5b5b5;border: none;min-width: 90px;min-height: 35px;color: white;border-radius: 100px;padding: 0 20px;}
.log-menu .dropdown-item{padding: .45rem 0.5rem !important;}
.log-menu{margin: 0;padding: 3px 0px;}
.ReactModal__Overlay{background-color: rgba(0, 0, 0, 0.45) !important;z-index:9999 !important;}
.search-btn{z-index:0 !important;}
.info-sec{color: #0c5460;background-color: #d1ecf1;border-color: #bee5eb;border: 1px solid transparent;border-radius: .25rem;padding: 15px;}
.info-header{border:none;}
.info-header h2{color: #0c5460;}
.custom_pagination li{border: 1px solid rgba(7, 7, 7, 0.1);padding: 5px;background: #fff;margin: 0px 3px;min-width: 40px;display: flex;align-items: center;justify-content: center;cursor: pointer;}
.custom_pagination li a {color: #737272;outline: none;text-decoration: none;display: block;width:100%;text-align: center;}
.custom_pagination li.active{background: #70bfe4;}
.custom_pagination li.active a{color:#fff;}
.custom_pagination li.previous{background-image: url("../images/arrow-left.svg");background-size: 40%;background-repeat: no-repeat;background-position: 45% 50%;}
.custom_pagination li.next{background-image: url("../images/arrow-right.svg");background-size: 40%;background-repeat: no-repeat;background-position: 45% 50%;}
.no-entity .info-message{font-size: 16px;color: #70bfe4;font-weight: 600;padding: 8px;}
#exampleFormControlSelect1, #roleSelect{border:none;}
#exampleFormControlSelect1 .css-vj8t7z,#roleSelect .css-vj8t7z{border: none;border-bottom: 1px solid #e6e6e6;border-radius: inherit;bottom: 6px;}
#exampleFormControlSelect1 .css-d8oujb,#roleSelect .css-d8oujb{background-color: transparent;}
.cursor {cursor:pointer}
.w-100{width:100%;}
.m-w-140{min-width:140px;}
.m-w-80{min-width:80px;}.m-w-90{min-width:90px;}
.m-w-15{min-width:115px;}
.changetext{font-size: 16px;color: #6fc1e8;font-weight: 600;cursor: pointer;text-decoration: underline;}
.cancel-btn{display: flex;align-items: center;background: #b5b5b5 !important;padding: 0;color: #fff !important;justify-content: center;}
.logo-2{margin-top: 10px;border-top: 1px solid rgba(255, 255, 255, 0.38);padding: 5px;}
.logo-2 img{width:200px;}
.modal-close{position: absolute;right: 15px;top: 3px; cursor: pointer;}
.modal-close img{width:10px;}
.deletealert{margin: 15px;min-height: 80px;}
.deletealert span{font-size: 18px;color: #6c757d;}
#sidebar-logo{display:flex;}
#sidebar-logo .sidelogo-1 img{width:100%;}
#sidebar-logo .sidelogo-2 img{width:100%;}
#sidebar-logo .sidelogo-1{padding: 5px;}
#sidebar-logo .sidelogo-2{padding: 5px;border-left:1px solid rgba(255, 255, 255, 0.4);}
.cancel-btn:hover{color:#fff;}
.search_panal input::placeholder,.Login-form input::placeholder{color:#cecece;}
.img-avatar{width:40px !important;}
.hidden {display: none}
.login-btn active { background: #6fc1e8;  color: #fff; }
.cancel-btn:hover{color:#fff;}
#roleSelect .css-xp4uvy{color:#85919F;} #roleSelect .css-1hwfws3{padding: 0;}
a{text-decoration: none !important;}
.img-loading{transition: opacity .5s ease-out;
  -moz-transition: opacity .5s ease-out;
  -webkit-transition: opacity .5s ease-out;
  -o-transition: opacity .5s ease-out;
  position: absolute;margin: auto;top: 0;bottom: 0;left: 0;right: 0;width: 50px;background: rgba(255, 255, 255, 0.6);display: flex;align-items: center;justify-content: center;padding: 5px;height: 50px;border-radius: 50%;border: 0.1px solid #dedede;margin:auto;}
.box-200 { width:200px;height:200px; }
.box-400 { width:400px;height:400px; }
.box-auto { width:auto;height:400px; }
.box-full { width:auto%;height:auto; }
.loading {background: transparent url('../images/loading.gif') center no-repeat;}
.center {text-align: center;}
.line-height-12 { line-height: 12px;}
.margin-top-50 {margin-top: 50px;}
.margin-left-20 { margin-left: 20px; }
.role-arrow{-webkit-appearance: none;}
.left-side-bar-bg{background: #313942}
.close-others{    border: 1px solid #ccc;
  padding: 8px;
  border-radius: 3px;
  background: #b5b5b5;}
  .sec_notify{
    margin: 3px;
    border: 1px solid rgba(204, 204, 204, 0.26);
    height: 160px;
    overflow-y: auto;
    padding: 3px;
  }
  .clear-btn{
    display: inherit;
    text-align: center;
    border: 1px solid #ccc;
    float: right;
    width: 75px;
    margin: 3px;
    font-size: 12px;
    padding: 3px;
    background: #b5b5b5;
    color: #fff;
    border-radius:25px;
  }
  .fileContainer .uploadPicturesWrapper{height:150px;overflow-y: auto;}
  .clear-btn:hover{color:#fff;}
  .disable-component{pointer-events: none;}
  /* .custom_multiselect .arrow{font-size: 8px;color: #85919F;display: flex;position: relative;top: -28px;width: 100%;right: 0;text-align: right;float: right;align-items: flex-end;
    justify-content: flex-end;line-height: 33px;}
  .custom_multiselect .selected-options{border:none;border-bottom: 1px solid #e6e6e6;border-radius: 0;}
  .custom_multiselect .multi-select{height:37px;}
  #Mulit-select .selected-options-badges{color: rgb(52, 59, 68) !important;background-color: rgba(112, 191, 228, 0.53) !important;}
  #Mulit-select .selected-options .selected-options-badges:after {content: '' !important;} */
  .custom_multiselect .css-vj8t7z{border:none;border-radius: 0;background: transparent;}
  .custom_multiselect .css-1hwfws3{bottom: 5px;}  
  .css-2o5izw:hover,.css-2o5izw:focus{border-color:transparent !important;border:none !important;box-shadow: none !important;border-color: transparent !important;border-style:none !important;}
  .css-2o5izw{border-color:transparent !important;border:none !important;box-shadow: none !important;border-color: transparent !important;border-style:none !important;background: transparent !important;}
  .ReactModal__Body--open{position:fixed;}
  .fileContainer .chooseFileButton{background: #a2d9f2;}
  .fileContainer .deleteImage{font-size: 15px;}
  .overflow-box{height:220px;overflow-y: auto;}
  .custom_pagination .previous a,.custom_pagination .next a{opacity: 0;}
  .custom_multiselect .css-1hwfws3{height:37px;overflow-y: auto;}
  .field-strong {font-weight: 400;}
  textarea.field-strong {
    max-height: 80px!important;
    min-height: 80px!important;
    height: 80px !important;
  }
  .label-strong label{font-weight: 400;}
  .p_5{padding: 5px;}
  .w-30{width:30px;}  
  .boxset-holder {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    flex-wrap: wrap;
  } 
  .boxset-holder .countbox-set {
    width: 20%;
    background: #fff;
    padding: 10px;
    margin-right: 15px;
    border-radius: 5px;
    box-shadow: 2px 2px 5px #e0dfdf;
    text-align: center;
    color: #abb2bb;
    margin-bottom: 10px;
  }
      .countbox-set .titl-label{color: #cacaca;
        font-weight: 600;}
       .countbox-set h3{font-size: 26px;
        padding: 10px 0;
        border-bottom: 1px solid #eee;
        width: 100%;
        text-align: center;font-weight: normal;
        line-height: inherit;}
        .boxset-holder .countbox-set a{color:#abb2bb;}
        .dropdown-menu-right:after{
          content: "";
    margin: 0 0.5em;
    display: inline-block;
    border: 10px solid transparent;
    border-bottom: 15px solid #f9f9f9;
    border-top: 0 none;
    position: absolute;
    top: -15px;
    right: 30px;
      }
    .dropdown-menu-right{border:none;box-shadow: 1px 1px 3px #bfbcbc;}
    .cursor-disable:hover{cursor: not-allowed;}
    .m-h-40{max-height:40px;}
    .boxset-holder .countbox-set:last-child{margin-right: 0;}
  /*media queries */
  @media only screen and (min-width: 991px) and (max-width: 1200px){
    .border_btm ul{font-size:12px;}
    .boxset-holder .countbox-set h6{font-size:0.6rem;}
  }
  @media only screen and (min-width: 1200px) and (max-width: 1400px){
    .boxset-holder .countbox-set h6{font-size:0.7rem;}
  }
  .breadcrumb-link{
    display: flex;
    align-items: center;
  }

  @-moz-document url-prefix() { 
    .ffoxSelectArrowFix {
      appearance: none;
      -moz-appearance: none;
      background-image:url(../images/arrow-down.svg) ;
      background-position: 94% 14px;
      background-repeat: no-repeat;
      background-size: 13px;
      padding-right: 30px;
    }
  }

.ant-menu li, .ant-menu li>div.ant-menu-submenu-title{
    padding-left: 10px !important;
    padding-right: 10px !important;
}
.ant-menu li.ant-menu-submenu{
    padding-left:0 !important;
    padding-right:0 !important;
}
li.ant-menu-submenu ul li.ant-menu-item{
    padding-left:45px !important;    
}
.ant-menu li.ant-menu-item svg>path, .ant-menu li li.ant-menu-item svg>path {
    fill: #b9c2c9 !important;
}

.ant-menu li.ant-menu-item-selected svg>path, .ant-menu li.ant-menu-submenu svg>path, .ant-menu li li.ant-menu-item-selected svg>path {
    fill: white !important;
}

.ant-menu li.ant-menu-item svg, .ant-menu li.ant-menu-submenu svg {
    margin-right: 10px;
}

li.ant-menu-item.ant-menu-item-selected, li.ant-menu-submenu-open.ant-menu-submenu-selected>div.ant-menu-submenu-title {
    background: #637484 !important;
    border-left:5px #70bfe4 solid;
}

li li.ant-menu-item.ant-menu-item-selected {
    background: transparent !important;
    border-left:0;
}
.ant-calendar-picker-icon{
  cursor: pointer;
}
.ant-steps-item{
  cursor:pointer !important;
}

.ant-menu>li {
  border-left: 5px transparent solid;
}

.ant-menu>li>ul>li {
  border-left: 0;
}

.ant-menu-submenu>ul>li>i {
  vertical-align: text-bottom;
  line-height: 23px;
}

.model-header>h2>i {
  display: inline-block;
  vertical-align: bottom;
  margin: 0 0 0 15px;
}

.model-header>h2>i>svg {
  width: 18px;
}

.login_section .login_right>span>I.anticon-home>svg {
  vertical-align: baseline;
}

.field_max_limit{
  color: #a3a6a8;
}
.cancel-btn-simple{
  display: flex;
  align-items: center;
  background: #b5b5b5 !important;
  padding: 0px;
  margin-top: 10px !important;
  color: #fff !important;
  justify-content: center;
}